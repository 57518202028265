import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function AboutPage() {
  return (
    <div
      className="flex-grow-1"
      style={{
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
        "margin-top": "1.7rem",
        "flex-direction": "column",
      }}
    >
      <h1 style={{ "font-size": "3rem" }}>About</h1>
      <p style={{ margin: "0 2rem 1rem 2rem" }}>
        This website is dedicated to people who love food and want to know where to get some good stuff to eat. The whole point of this website is to display interactive maps of where food can be found at festivals (e.g. Fairs, Farmers Markets, Christkindlmarkt, etc.) around the indianapolis metropolitan area. This website is ran by a single person and I do all the work to gather all the pictures and data. As of now, the only map that has been created is the Indiana State Fair of 2023, and this will be recurring for years to come. The hope is to expand to other festival events, but I have to start somewhere...
      </p>
    </div>
  );
}

export default AboutPage;
