import React from "react";
import { Container } from "react-bootstrap";

function PageFooter() {
  return (
    <footer className="footer py-3 bg-light mt-auto">
      <Container className="text-center">
        <p>Created by Jake Waggoner</p>
      </Container>
    </footer>
  );
}

export default PageFooter;
