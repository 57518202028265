import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageHeader from "./navbar";
import PageFooter from "./footer";
import About from "./About";
import Home from "./Home";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <PageHeader />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
        </Switch>
        <PageFooter />
      </div>
    </Router>
  );
}

export default App;
