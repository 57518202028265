import React from "react";
import logoS3 from "./logoS3.png";

function AboutPage() {
  return (
    <div  style={{
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      "margin-top": "1.7rem",
      "flex-direction": "column",
    }}>
      <h1 style={{ "font-size": "3rem" }}>Indiana State Fair 2023 Food Vendor map</h1>
      <iframe src="https://www.google.com/maps/d/embed?mid=1AobU9oXm196i6WkFRzngqjeLQz_yxRQ&ehbc=2E312F" width="640" height="480"></iframe>
    </div>
  );
}

export default AboutPage;
